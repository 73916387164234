import JwtData from "@/common/jwt/JwtData";
import JwtUtils from "@/common/jwt/JwtUtils";
import axios, {AxiosRequestConfig} from "axios";
import store from "@/common/MyStore";
import UserData from "@/common/data/UserData";
import UserPerms from "@/common/UserPerms";
import ExtBoardData from "@/pb/data/ExtBoardData";
import {getModule} from "vuex-module-decorators";
import CommonModule from "@/common/module/CommonModule";

export default class MyPbxToolsApi {
    public static readonly BASE_URL = 'https://instadial.co.uk/';
    private static readonly API_URL = MyPbxToolsApi.BASE_URL + '/api';
    private static readonly GENERAL_API_URL = MyPbxToolsApi.API_URL + '/general/v1.0';

    private jwtData: JwtData | null = null;
    public jwtToken: string | null = null;
    private static readonly JWT_TOKEN_NAME = "jwtToken";

    public static readonly instance: MyPbxToolsApi = new MyPbxToolsApi();


    public constructor() {
        this.autoLoad();
    }

    public static get globalJwtToken(): string | null {
        return localStorage.getItem(this.JWT_TOKEN_NAME) ?? null;
    }

    public static set globalJwtToken(value: string | null) {
        if (value == null)
            this.clearGlobalJwtToken();
        else
            localStorage.setItem(this.JWT_TOKEN_NAME, value);
    }

    public static clearGlobalJwtToken() {
        localStorage.removeItem(this.JWT_TOKEN_NAME);
    }


    public clearTokens() {
        this.jwtData = null;
        this.jwtToken = null;
        MyPbxToolsApi.clearGlobalJwtToken();
    }

    get expireDate(): Date | null {
        return this.jwtData ? JwtUtils.fromJwtTime(this.jwtData.exp) : null;
    }

    get needsLogin(): boolean {
        if (this.jwtData === null) {
            return true;
        }
        const now = new Date();
        const then = JwtUtils.fromJwtTime(this.jwtData.exp);
        return now > then;
    }

    get authHeaderContent(): string {
        return `Bearer ${this.jwtToken}`;
    }

    private static async login(email: string, password: string, timeout?: string): Promise<string> {
        const data = new FormData();
        data.set('email', email);
        data.set('password', password);
        if (timeout) {
            data.set('timeout', timeout);
        }
        const response = await axios.post(MyPbxToolsApi.GENERAL_API_URL + "/login.php", data);
        const res = response.data;

        if (typeof (res) === 'object' && res.error)
            throw res.error;

        return res;
    }

    get authHeaders(): any {
        return {
            'Authorization': this.authHeaderContent,
        }
    }

    get authConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Authorization': this.authHeaderContent,
                'Content-Type': 'multi-part/form-data'
            }
        }
    }

    async handleJwtAsync(jwt: string) {
        this.jwtToken = jwt;
        this.jwtData = JwtUtils.fromText(jwt);
        MyPbxToolsApi.globalJwtToken = jwt;
        await getModule(CommonModule, store).init(true);
    }

    async doLoginAsync(email: string, password: string, timeout?: string): Promise<void> {
        const res = await MyPbxToolsApi.login(email, password, timeout);
        await this.handleJwtAsync(res);
    }

    autoLoad() {
        if (this.jwtToken === null) {
            this.jwtToken = MyPbxToolsApi.globalJwtToken;
        }
        if (this.jwtToken !== null) {
            this.jwtData = JwtUtils.fromText(this.jwtToken);
        }
    }

    async doUserAction<T>(action: string, data: any = {}): Promise<T> {
        const resp = await axios.post(`${MyPbxToolsApi.GENERAL_API_URL}/users.php?action=${encodeURIComponent(action)}`, data, this.authConfig);
        const res = resp.data;
        if (res.error)
            throw res.error;
        return res;
    }

    async getUserPerms(id: number): Promise<UserPerms> {

        return await this.doUserAction('list_perms', {id: id});
    }

    async listUsers(): Promise<UserData[]> {
        return await this.doUserAction("list");
    }

    async getUserData(id: number): Promise<UserData> {
        return await this.doUserAction("get", {id});
    }

    async getMyUserData(): Promise<UserData> {
        return await this.doUserAction("get");
    }

    async doWbActionGeneric<TRes>(entity: string, action: string, data: any = {}): Promise<TRes> {
        data['action'] = action;
        return axios.post(`${MyPbxToolsApi.API_URL}/wallboards/v1.0/admin/${entity}.php`, data, this.authConfig)
            .then((resp) => {
                if ((resp.data?.success ?? true) === false) {
                    throw new DOMException(`Error: ${resp.data.error}`);
                }
                return resp.data;
            });
    }

    async doWbTileAction<TRes>(wbId: number, tileId: number, action: string, data: any = {}): Promise<TRes> {
        data.tileId = tileId;
        data.wallboardId = wbId;
        return await this.doWbActionGeneric('tiles', action, data);
    }

    async doWbAction<TRes>(wbId: number, action: string, data: any = {}): Promise<TRes> {
        data.id = wbId;
        return await this.doWbActionGeneric('board', action, data);
    }

    async getWbInfoByToken(token: string): Promise<ExtBoardData> {
        return axios.get(`${MyPbxToolsApi.API_URL}/wallboards/v1.0/get_config.php?token=${encodeURIComponent(token)}`)
            .then(resp => {
                if ((resp.data?.success ?? true) === false) {
                    throw new DOMException(`Error: ${resp.data.error}`);
                }
                return resp.data;
            });
    }
}