import BoardPos from "@/pb/BoardPos";

import BoardPosUtils from "@/common/data/BoardPosUtils";
import Direction from "@/common/data/Direction";
import BoardTile from "@/pb/data/BoardTile";

type GridTile = BoardTile | null;

export default class TilesGrid {
    static readonly NUM_ROWS = 4;
    static readonly NUM_COLS = 8;
    private readonly tiles: BoardTile[];
    private static readonly posFields = ['row', 'column', 'rows', 'columns'];

    constructor(tiles: BoardTile[]) {
        this.tiles = tiles;
    }

    get firstFreeTile(): null | BoardPos {
        const first = this.freeTilesIterator().next();
        return first.done ? null : first.value;
    }

    isValidPos(column: number, row: number): boolean {
        return row >= 0 && column >= 0 && row < TilesGrid.NUM_ROWS && column < TilesGrid.NUM_COLS;
    }

    isValidPosObj(pos: BoardPos): boolean {
        return this.isValidPos(pos.column, pos.row);
    }

    get tilesLayout(): GridTile[][] {
        const data: GridTile[][] = [];
        const tiles = this.tiles;

        for (let row = 0; row < TilesGrid.NUM_ROWS; row++) {
            const cols: GridTile[] = [];
            for (let col = 0; col < TilesGrid.NUM_COLS; col++) {
                cols.push(null);
            }
            data.push(cols);
        }
        for (const tile of tiles) {
            for (let row = tile.row; row < tile.row + tile.rows; row++) {
                if (data[row] === undefined) {
                    console.error(`Tile out of bounds: row = ${row}`, tile);
                    continue;
                }
                for (let col = tile.column; col < tile.column + tile.columns; col++) {
                    data[row][col] = tile;
                }
            }
        }
        return data;
    }

    get canCreateOrDuplicate() {

        return this.firstFreeTile !== null;
    }
    freeTiles(): Iterable<BoardPos> {
        const grid = this;
        return {
            [Symbol.iterator](): Iterator<BoardPos> {
                return grid.freeTilesIterator();
            }
        }
    }

    autoPlaceTile(tile: BoardTile) {
        const pos = this.firstFreeTile;
        if (pos == null)
            return;

        tile.row = pos.row;
        tile.column = pos.column;
    }

    checkAllowedPos(tile: BoardTile, column: number, row: number, skipValidCheck: boolean = false): boolean {
        const rows = tile.rows;
        const columns = tile.columns;


        if (!skipValidCheck && !this.isValidPos(column, row)) {
            return false;
        }

        const max: BoardPos = {row: rows + row, column: column + columns};

        if (max.row > TilesGrid.NUM_ROWS || max.column > TilesGrid.NUM_COLS) {
            return false;
        }

        const layout = this.tilesLayout;
        for (let cRow = row; cRow < row + rows; cRow++) {
            const row = layout[cRow];
            if (row == null) {
                throw `Row not found at ${cRow} for ${tile}`;
            }


            for (let cCol = column; cCol < column + columns; cCol++) {
                const cTile = row[cCol];
                if (cTile !== null && cTile.id !== tile.id)
                    return false;
            }
        }
        return true;
    }

    isAllowedMove(tile: BoardTile, dir: Direction): boolean {
        const diff = BoardPosUtils.fromDir(dir);
        const newPos = BoardPosUtils.add(tile, diff);
        return this.checkAllowedPos(tile, newPos.column, newPos.row);
    }

    isAllowedSwap(tile: BoardTile, dir: Direction): boolean {
        const diff = BoardPosUtils.fromDir(dir);
        const newPos = BoardPosUtils.add(tile, diff);
        return !this.checkAllowedPos(tile, newPos.column, newPos.row, true) && this.tilesLayout[newPos.row][newPos.column] !== null;
    }

    isAllowedMoveOrSwap(tile: BoardTile, dir: Direction): boolean {
        const diff = BoardPosUtils.fromDir(dir);
        const newPos = BoardPosUtils.add(tile, diff);
        return this.isValidPosObj(newPos) && (this.isAllowedMove(tile, dir) || this.isAllowedSwap(tile, dir));
    }

    autoPlaceTileNear(tile: BoardTile, origTile: BoardTile) {
        const freeTiles = Array.from(this.freeTiles());
        const dirs = BoardPosUtils.AllDirs;
        const halfTileSize: BoardPos = {column: tile.columns / 2, row: tile.rows / 2};
        const origTileCenter = BoardPosUtils.add(origTile, halfTileSize);

        const nearby = dirs.map(dir => {
            let curr = dir;
            curr = BoardPosUtils.mul(curr, halfTileSize);
            curr = BoardPosUtils.add(curr, origTileCenter);
            return curr;
        })

        const chosen = nearby.find(pos => freeTiles.find(BoardPosUtils.overlap.bind(null, pos)) != null);
        if (chosen) {
            tile.row = chosen.row;
            tile.column = chosen.column;
        } else {
            this.autoPlaceTile(tile);
        }
    }

    private* freeTilesIterator(): Iterator<BoardPos> {
        const layout = this.tilesLayout;
        for (let row = 0; row < TilesGrid.NUM_ROWS; row++) {
            for (let col = 0; col < TilesGrid.NUM_COLS; col++) {
                if (layout[row][col] === null) {
                    yield {
                        row: row,
                        column: col
                    };
                }
            }
        }
    }
}