import BoardPos from "@/pb/BoardPos";
import Direction from "@/common/data/Direction";

export default class BoardPosUtils {
    static readonly Left: BoardPos = {column: -1, row: 0};
    static readonly Right: BoardPos = {column: 1, row: 0};
    static readonly Up: BoardPos = {column: 0, row: -1};
    static readonly Down: BoardPos = {column: 0, row: 1};
    static readonly UpLeft: BoardPos = {column: -1, row: 1};
    static readonly UpRight: BoardPos = {column: 1, row: -1};
    static readonly DownLeft: BoardPos = {column: -1, row: 1};
    static readonly DownRight: BoardPos = {column: 1, row: 1};

    static readonly FixedDirs = [
        BoardPosUtils.Left,
        BoardPosUtils.Right,
        BoardPosUtils.Up,
        BoardPosUtils.Down
    ];

    static readonly NonFixedDirs = [
        BoardPosUtils.UpLeft,
        BoardPosUtils.UpRight,
        BoardPosUtils.DownLeft,
        BoardPosUtils.DownRight
    ]
    static readonly AllDirs = [...BoardPosUtils.NonFixedDirs, ...BoardPosUtils.FixedDirs];

    static add(a: BoardPos, b: BoardPos): BoardPos {
        return {
            row: a.row + b.row,
            column: a.column + b.column
        }
    }

    static mul(a: BoardPos, b: BoardPos): BoardPos {
        return {
            row: a.row * b.row,
            column: a.column * b.column
        }
    }

    static overlap(a: BoardPos, b: BoardPos): boolean {
        return a.row === b.row && a.column === b.column;
    }

    static fromDir(dir: Direction): BoardPos {
        switch (dir) {
            case Direction.Down:
                return this.Down;
            case Direction.Up:
                return this.Up;
            case Direction.Left:
                return this.Left;
            case Direction.Right:
                return this.Right;
            default:
                throw `Invalid dir:${dir}`;
        }
    }
}