import Vue from 'vue'
import VueRouter, {RouteRecord} from 'vue-router'
import _findLast from 'lodash.findlast';
import store from '@/common/MyStore.ts';
import AuthType from "@/router/AuthType";
import routes from "@/router/routes";
import {getModule} from "vuex-module-decorators";
import CommonModule from "@/common/module/CommonModule";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'hash'
});


router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/login/") && typeof (to.params.jwt) === 'string') {
    const api = MyPbxToolsApi.instance;
    api.handleJwtAsync(to.params.jwt).catch(console.error);
    next('/');
    return;
  }
  const titleMeta = _findLast(to.matched, (r: RouteRecord) => r.meta?.title != null) as RouteRecord | undefined;
  const title = titleMeta?.meta?.title;
  if (title) {
    document.title = `${title} - MyPBXTools`;
  }
  next();

});

router.afterEach((to, from) => {
  const toMeta = to.matched[0]?.meta;
  if (!toMeta) {
    return;
  }
  const authType: AuthType = toMeta.auth;
  if (authType != AuthType.None) {
    // requires login
    const stored = getModule(CommonModule, store).api;
    if (stored.needsLogin) {
      router.push({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      }).catch(console.error);
    }
  }
});
export default router;
