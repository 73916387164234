import {RouteConfig} from "vue-router";
import store from "@/common/MyStore";
import AuthType from "@/router/AuthType";
import {getModule} from "vuex-module-decorators";
import CommonModule from "@/common/module/CommonModule";

const routes: RouteConfig[] = [
    {
        path: '/notification/:msg?',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/Notify.vue')}],
        meta: {
            title: 'Notification',
            auth: AuthType.None
        }
    },
    {
        path: '/login/:jwt?',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/Login.vue')}],
        meta: {
            title: 'Login',
            auth: AuthType.None
        }
    },
    {
        path: '/',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/Home.vue')}],
        meta: {
            title: 'Dashboard',
            auth: AuthType.Basic
        }
    },
    {
        path: '/user-manager',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/UserManager.vue')}],
        meta: {
            title: 'User Manager',
            auth: AuthType.Basic
        }
    },
    {
        path: '/pb/board/:token',
        component: () => import("@/layouts/PowerboardLayout.vue"),
        meta: {
            title: `Powerboard Manager`,
            auth: AuthType.Basic
        }
    },
    {
        path: '/pb/template/:token',
        component: () => import("@/layouts/PowerboardLayout.vue"),
        props: {
            isTemplate: true
        },
        meta: {
            title: `Powerboard Manager`,
            auth: AuthType.Basic
        }
    },
    {
        path: '/pb/boards/:id?',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/powerboards/Boards.vue')}],

        meta: {
            title: 'Powerboards',
            auth: AuthType.Basic
        }
    },
    {
        path: '/pb/templates',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/powerboards/Templates.vue'),}],
        meta: {
            title: 'Powerboard Templates',
            auth: AuthType.Basic
        }
    },
    {
        path: '/pb/instances',
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{path: "", component: () => import('@/pages/powerboards/Instances.vue')}],
        meta: {
            title: 'Powerboard Instances',
            auth: AuthType.Basic
        }
    },
    {
        path: "/logout",
        name: "Log Out",
        component: {
            beforeRouteEnter(to, from, next) {
                console.log(store);
                const common = getModule(CommonModule, store);
                console.log(common);
                common.forceClear();

                next({
                    path: "/login",
                    query: {

                        redirect: from.path
                    }

                });
            }
        }
    }
];
export default routes;