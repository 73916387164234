import Vue from 'vue';
import Vuetify from "vuetify";
// @ts-ignore
import {VCascader, VTooltipBtn} from 'vuetify-toolkit/vuetify-toolkit.umd';


Vue.use(Vuetify, {VCascader, VTooltipBtn});

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#0072FF',
                secondary: '#00DD00',
                error: '#FFC580',
                accent: '#7BB6FF'
            }
        }
    },
});