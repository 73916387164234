import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import store from "@/common/MyStore";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  vuetify,
  render: h => h(App),
  store: store
}).$mount('#app');

setInterval(async () => {
  const api = MyPbxToolsApi.instance;
  const route = router.currentRoute;
  if (route.path === '/login')
    return;
  if (api.needsLogin)
    await router.push('/logout');
}, 1000 * 10);