import jwtDecode from "jwt-decode";
import JwtData from "@/common/jwt/JwtData";


export default class JwtUtils {
    static fromText(text: string): JwtData {
        return jwtDecode<JwtData>(text);
    }

    static fromJwtTime(num: number): Date {
        const epoch = num * 1000 + (new Date().getTimezoneOffset() * -1);
        return new Date(epoch);
    }

}