






import {Route} from "vue-router";
import {Component, Vue, Watch} from "vue-property-decorator";

@Component
export default class App extends Vue {
  pageTitle: string = ""

  @Watch('$route')
  onRouteChanged(newRouteValue: Route) {
      if (newRouteValue.matched.length) {
          console.log(this.pageTitle = newRouteValue.matched[0].meta.title);
      }
  }
}
