import Vuex from "vuex";
import Vue from "vue";
import CommonModule from "@/common/module/CommonModule";
import WallboardModule from "@/common/module/WallboardModule";
import {getModule} from "vuex-module-decorators";


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        common: CommonModule,
        wb: WallboardModule
    }
});

getModule(CommonModule, store).init();

export default store;