import axios from "axios";
import JwtUtils from "@/common/jwt/JwtUtils";
import JwtData from "@/common/jwt/JwtData";
import FilterData from "@/pb/data/FilterData";
import BaseInstance from "@/pb/data/BaseInstance";

export default class BackendRest {
    private jwtToken?: string;
    private jwt?: JwtData;
    private readonly hostUrl: string;
    private readonly shaPass: string;


    constructor(hostUrl: string, shaPass: string) {

        this.hostUrl = hostUrl;
        this.shaPass = shaPass;

    }

    static create(instance: BaseInstance): BackendRest {
        return new BackendRest(`https://${instance.fqdn}:8011`, instance.passwordSha512);
    }

    get authExpireTime(): Date | null {
        if (this.jwt === null)
            return null;

        return JwtUtils.fromJwtTime(this.jwt!.exp);
    }

    get needsReauth(): boolean {
        return typeof (this.jwt) !== 'object' || this.authExpireTime! < new Date();
    }

    private get backendHttpConfig() {
        return {
            headers: {Authorization: `Bearer ${this.jwtToken}`}
        };
    }

    async authAsync() {
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        };
        if (this.hostUrl === null || this.hostUrl.length === 0) {
            throw `Invalid host URL: ${this.hostUrl}`;
        }
        const resp = await axios.post(`${this.hostUrl}/authenticate`, {shaPassword: this.shaPass}, {
            headers: headers
        });
        const res = resp.data;

        this.jwtToken = res.token;
        this.jwt = JwtUtils.fromText(res.token);


        return res;
    }

    async autoAuthAsync() {
        if (!this.needsReauth) {
            return;
        }
        await this.authAsync();
    }

    async getFilterGroupsAsync(): Promise<FilterData> {

        await this.autoAuthAsync();
        const res = await axios
            .get(`${this.hostUrl}/filterGroups/list`, this.backendHttpConfig);
        return res.data;
    }

    async forceConfigReloadAsync(token: string) {
        if (token == '' || token == null)
            throw 'Invalid token!';
        return axios.post(`${this.hostUrl}/forceConfigReload/${token}`, '', this.backendHttpConfig);
    }
}