import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import TilesGrid from "@/pb/TilesGrid";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import ExtBoardData from "@/pb/data/ExtBoardData";
import BackendRest from "@/pb/BackendRest";
import FilterData from "@/pb/data/FilterData";
import BoardTile from "@/pb/data/BoardTile";

@Module({name: 'wb'})
export default class WallboardModule extends VuexModule {
    wb: ExtBoardData = {
        statsResetInterval: 'day_start',
        tiles: [],
        id: -1,
        template: false,
        backendId: -1,
        bestCriteria: "",
        title: "N/A",
        token: "",
        globalFilter: "N/A",
        idleTimeTimeouts: [{success: -1, warning: -1, error: -1}],
        backend: {
            name: "N/A",
            token: "N/A",
            callsFilter: "all",
            password: undefined,
            dbClearInterval: "never;",
            fqdn: "N/A",
            licenceKey: "N/A",
            ownerId: -1,
            ownerName: "N/A",
            passwordSha512: "N/A"
        }
    };
    backendRest: BackendRest | null = null;
    token: string = "";
    filterOptions: FilterData | null = null;

    get tiles(): BoardTile[] {
        return this.wb.tiles;
    }

    set tiles(v: BoardTile[]) {
        this.wb.tiles = v;
    }

    get grid(): TilesGrid {
        return new TilesGrid(this.tiles);
    }
    @Mutation
    init(token: string) {
        this.token = token;
    }

    @Action
    async trueInit(token: string) {
        this.init(token);
        await this.reload();
    }

    @Mutation
    async reload() {
        this.wb = await MyPbxToolsApi.instance.getWbInfoByToken(this.token);

        this.backendRest = BackendRest.create(this.wb.backend);
        await this.backendRest.authAsync();

        this.filterOptions = await this.backendRest.getFilterGroupsAsync();
    }

    @Mutation
    async forceConfigReload() {
        if (this.backendRest == null) {
            throw 'No backend!';
        }

        await this.backendRest.forceConfigReloadAsync(this.token);
    }


    @Mutation
    updateTiles(tiles: BoardTile[]) {
        this.tiles = tiles;
    }


    @Mutation
    async updateTile(tile: Partial<BoardTile>) {
        const tileId = tile.id!;
        await MyPbxToolsApi.instance.doWbTileAction<BoardTile>(this.wb.id, tileId, 'update', tile);
        await this.backendRest?.forceConfigReloadAsync(this.wb.token);
    }

    @Mutation
    addTile(tile: BoardTile) {
        this.tiles = [...this.tiles, tile];
    }

    @Mutation
    async deleteTile(tile: BoardTile) {
        await MyPbxToolsApi.instance.doWbTileAction<BoardTile>(this.wb.id, tile.id, 'delete');
        this.wb.tiles = this.wb.tiles.filter((f: BoardTile) => tile.id !== f.id);
    }

    @Mutation
    async duplicateTile(tile: BoardTile): Promise<BoardTile> {
        const newTile = await MyPbxToolsApi.instance.doWbTileAction<BoardTile>(this.wb.id, tile.id, 'copy');
        const newIndex = this.wb.tiles.indexOf(tile) + 1
        this.wb.tiles.splice(newIndex, 0, newTile);
        const grid = new TilesGrid(this.wb.tiles);
        grid.autoPlaceTileNear(newTile, tile);


        return newTile;
    }
}