import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import UserData from "@/common/data/UserData";
import UserPerms from "@/common/UserPerms";
import {Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module({name: 'common'})
export default class CommonModule extends VuexModule {
    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    userData: UserData | null = null;
    userPerms: UserPerms | null = null;

    get needsLogin(): boolean {
        return this.userData == null;
    }

    get loggedIn(): boolean {
        return this.userData !== null;
    }

    @Mutation
    forceClear() {
        this.userData = this.userPerms = null;
        MyPbxToolsApi.instance.clearTokens();
    }

    @Mutation
    async init(force: boolean = false) {

        if (!force && this.needsLogin) {
            throw ('needs login');
            return;
        }
        const api = MyPbxToolsApi.instance;
        console.log('api', MyPbxToolsApi.instance);
        this.userData = await api.getMyUserData();
        if (this.userData !== null) {
            this.userPerms = await api.getUserPerms(this.userData.id);
        }

    }
}